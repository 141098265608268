<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showform"
      max-width="1200px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span>
            {{
              editedItem.id > 0
                ? "Reference # " + editedItem.code
                : "New Reference"
            }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            :disabled="
              elements_list.findIndex((elm) => elm.value != null) == -1
            "
            @click.stop="validate"
            v-if="
              editedItem.id > 0 &&
              editedItem.statut_id == 5 &&
              elements_list.length > 0
            "
            class="ma-1"
          >
            Validate
          </v-btn>
          <v-btn
            color="blue darken-1"
            :disabled="change == 1"
            @click.stop="save"
            class="ma-1"
            v-if="editedItem.statut_id == 5"
          >
            Save
          </v-btn>

          <v-btn class="ma-1" color="blue darken-1" @click.stop="close">
            Close
          </v-btn>
        </v-card-title>
        <v-card-subtitle>Status : {{ item.status }}</v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="2" md="2">
                <v-select
                  :items="formations_fields_fluids"
                  v-model="editedItem.field_id"
                  item-value="field_id"
                  item-text="field_label"
                  label="Field"
                  :rules="[(v) => !!v || 'Field required']"
                  outlined
                  :readonly="
                    elements_list.length > 0 || editedItem.statut_id == 6
                  "
                  @input="change++"
                  @change="field_change"
                  :disabled="field_id > 0"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-autocomplete
                  :items="formations_list"
                  v-model="editedItem.formation_id"
                  item-value="formation_id"
                  item-text="formation_label"
                  label="Formation"
                  :rules="[(v) => !!v || 'Formation required']"
                  outlined
                  :readonly="editedItem.statut_id == 6"
                  :disabled="formation_id > 0"
                  @input="change++"
                  @change="formation_change"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-select
                  :items="fluids_list"
                  v-model="editedItem.fluid_id"
                  item-value="fluid_id"
                  item-text="fluid_label"
                  label="Fluid"
                  :rules="[
                    (v) => !!v || 'Fluid required',
                    (v) => nb_fluid > 0 || 'No Elements defined ',
                  ]"
                  outlined
                  :readonly="
                    elements_list.length > 0 || editedItem.statut_id == 6
                  "
                  @input="change++"
                  :disabled="fluid_id > 0"
                  dense
                >
                </v-select>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-autocomplete
                  :items="wells_list"
                  v-model="editedItem.well_id"
                  item-value="id"
                  item-text="wellname"
                  label="Well Name"
                  outlined
                  :readonly="editedItem.statut_id == 6"
                  @input="change++"
                  @change="well_change"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <datepicker
                  label="Reference Date"
                  v-model="editedItem.test_date"
                  :rules="[(v) => !!v || 'Reference Date required']"
                  :edit="editedItem.statut_id == 5"
                  :date_max="today"
                  :date_min="well.drillingdate"
                  @change="change++"
                  :key="cs"
                ></datepicker>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-autocomplete
                  :items="users_exe"
                  v-model="editedItem.agent_id"
                  item-value="id"
                  item-text="name"
                  label="Conducted by"
                  outlined
                  :readonly="editedItem.statut_id == 6"
                  @input="change++"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedItem.comment"
                  label="Comment"
                  outlined
                  :readonly="editedItem.statut_id == 6"
                  @input="change++"
                  dense
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="editedItem.id > 0">
                <v-card>
                  <v-card-title>
                    <span> Elements </span>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-actions>
                    <v-container element>
                      <v-form ref="form2" lazy-validation>
                        <v-container element>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="7"
                              md="7"
                              v-if="editedItem.statut_id == 5"
                            >
                              <v-autocomplete
                                :items="fluids_elements"
                                v-model="newelement.element_id"
                                label="Element"
                                :item-value="'id'"
                                :item-text="'label'"
                                :rules="[(v) => !!v || 'Element required']"
                                readonly
                                outlined
                                dense
                              >
                              </v-autocomplete>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                              v-if="editedItem.statut_id == 5"
                            >
                              <v-text-field
                                ref="elmval"
                                autocomplete="off"
                                type="number"
                                outlined
                                dense
                                v-model.number="newelement.value"
                                :label="
                                  'Value ' +
                                  (newelement.unit
                                    ? '(' + newelement.unit + ')'
                                    : '')
                                "
                                :rules="[(v) => v != null || 'Value required']"
                                hide-spin-buttons
                                @keydown.enter="save_element"
                                @focus="$event.target.select()"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="1"
                              md="1"
                              v-if="editedItem.statut_id == 5"
                            >
                              <v-btn
                                color="blue darken-1"
                                :disabled="newelement.element_id == null"
                                @click.stop="save_element"
                              >
                                Save
                              </v-btn>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="1"
                              md="1"
                              v-if="editedItem.statut_id == 5"
                            >
                              <v-btn
                                color="blue darken-1"
                                :disabled="newelement.element_id == null"
                                @click.stop="cancel"
                              >
                                Cancel
                              </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <listitems
                                :list="elements_list"
                                :headers="elements_headers"
                                :showstd="false"
                                :showedit="false"
                                :add="false"
                                :del="false"
                                :master="false"
                                :print="false"
                                :exp_excel="true"
                                :key="element_key"
                                :ipg="-1"
                                :hdf="true"
                                :btn_update="editedItem.statut_id == 5"
                                @btn_update_click="OpenImportForm"
                                @rowselect="ElementSelect"
                                @col_btn1_click="element_update"
                                @col_btn2_click="element_delete"
                                :laoding="data_loading"
                                :selitem="actualelement"
                              >
                              </listitems>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-container>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <import_data
      :showForm="!isListClose"
      :title="title"
      :listheaders="listheaders"
      :items="elements_list"
      :init_list="elements_list"
      :item_key="'element_label'"
      :col_key="'Element'"
      :item_id="'element_id'"
      :width="width"
      :alert="alert"
      :type_alert="type_alert"
      :message="message"
      @close="isListClose = true"
      @save_import="save_import"
      :key="frml"
    ></import_data>
  </v-form>
</template>
<script>
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
import CREATE_FP_DETAIL from "../graphql/FingerPrint/CREATE_FP_DETAIL.gql";
import UPDATE_FP_DETAIL from "../graphql/FingerPrint/UPDATE_FP_DETAIL.gql";
import DELETE_FP_DETAIL from "../graphql/FingerPrint/DELETE_FP_DETAIL.gql";
import INSERT_FP from "../graphql/FingerPrint/INSERT_FP.gql";
import UPDATE_FP from "../graphql/FingerPrint/UPDATE_FP.gql";
import FP_UPDATE from "../graphql/FingerPrint/FP_UPDATE.gql";
import FPS_ALL_DETAIL from "../graphql/FingerPrint/FPS_ALL_DETAIL.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    import_data: () => import("../components/ImportData.vue"),
  },
  props: {
    list: Array,
    showform: Boolean,
    modify: Boolean,
    item: Object,
    formations_fields_fluids: Array,
    fluids_elements: Array,
    user_list: Array,
    wells: Array,
    well_id: Number,
    field_id: String,
    formation_id: String,
    fluid_id: String,
  },
  data() {
    return {
      cs: 0,
      tab0: "1",
      document: {},
      isDocClose: true,
      ff: 900,
      element_key: 1000,
      elm_key: 1100,
      return_list: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      editedItem: {},
      change: 0,
      depth_min: 0,
      depth_max: 0,
      element: {},
      actualelement: {},
      newelement: {},
      data_loading: false,

      elements_headers: [
        {
          text: "N°",
          value: "no",
          selected: true,
          width: "10",
          sortable: false,
        },

        {
          text: "Element",
          value: "element_label",
          selected: true,
          width: "150",
          sortable: false,
          import: true,
        },
        {
          text: "Unit",
          value: "unit",
          selected: true,
          sortable: false,
          import: true,
        },
        {
          text: "Value",
          value: "value",
          selected: true,
          width: "150",
          sortable: false,
          import: true,
        },
        {
          text: "Update",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          hiden: false,
          tooltip: "Update element",
          sortable: false,
        },
      ],
      well: {},
      fluid_elements: [],
      elements_list: [],
      fluids_list: [],
      btn_click: false,
      element_edit: false,
      ch2: null,
      title: "",
      isListClose: true,
      width: "1000px",
      alert: false,
      type_alert: "info",
      message: "",
      frml: 600,
      listheaders: [],
      formations_list: [],
    };
  },
  watch: {},
  created() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.elements_headers[4].hiden = this.editedItem.statut_id == 6;
        this.cs++;
        this.elements_list = this.editedItem.details
          ? this.editedItem.details
          : [];
        this.element_key++;
      } else
        this.editedItem = Object.assign({}, { id: -1, statut_id: 5, ref: "R" });
      if (this.field_id) {
        this.editedItem.field_id = this.field_id;
        this.field_change();
      }
      if (this.formation_id) {
        this.editedItem.formation_id = this.formation_id;
        this.formation_change();
      }
      if (this.fluid_id) {
        this.editedItem.fluid_id = this.fluid_id;
      }
    }
  },
  computed: {
    today() {
      return this.$store.state.today;
    },
    wells_list() {
      let l = [];
      if (this.wells)
        l = this.wells.filter(
          (elm) => elm.field_id == this.editedItem.field_id
        );
      return l;
    },

    elements_reste() {
      let l = this.fluids_elements.filter(
        (elm3) =>
          (!this.elements_list
            .map((elm2) => parseInt(elm2.element_id))
            .includes(parseInt(elm3.id)) ||
            elm3.id == this.newelement.element_id) &&
          elm3.fluid_id == this.editedItem.fluid_id
      );
      return l;
    },
    users_exe() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("06003"));
      return l;
    },
    nb_fluid() {
      let l = [];
      l = this.fluids_elements.filter(
        (elm3) => elm3.fluid_id == this.editedItem.fluid_id
      );
      return l.length;
    },
  },
  methods: {
    field_change() {
      this.formations_list = this.formations_fields_fluids.filter(
        (elm) => elm.field_id == this.editedItem.field_id
      );
      var unique = this.wells_list.map((elm) => elm.well_id).filter(onlyUnique);
      if (unique.length == 1) {
        this.editedItem.well_id = unique[0];
        this.well_change();
      }
    },
    well_change() {
      let i = this.wells_list.findIndex(
        (elm) => elm.id == this.editedItem.well_id
      );
      if (i > 0) this.well = this.wells_list[i];
      var unique = this.formations_list
        .map((elm) => elm.formation_id)
        .filter(onlyUnique);
      if (unique.length == 1) {
        this.editedItem.formation_id = unique[0];
        this.formation_change();
      }
    },
    formation_change() {
      this.fluids_list = this.formations_fields_fluids.filter(
        (elm) =>
          elm.field_id == this.editedItem.field_id &&
          elm.formation_id == this.editedItem.formation_id
      );
      var unique = this.formations_list
        .map((elm) => elm.fluid_id)
        .filter(onlyUnique);
      if (unique.length == 1) {
        this.editedItem.fluid_id = unique[0];
      }
    },
    OpenImportForm() {
      this.form_list = [];
      this.title = "Import elements data ";
      this.listheaders = this.elements_headers.filter(
        (elm) => elm.import == true
      );
      this.width = "1000px";
      this.orientation = "p";
      this.isListClose = false;
      this.frml++;
    },
    async save_import(items) {
      let ok = true;
      this.message = null;
      let list = [];
      for (let index = 0; index < items.length; index++) {
        const element = items[index];

        if (element.value != null && typeof element.value != "number" && ok) {
          this.message = "Invalid Value Number" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }

        list.push({
          id: element.id,
          value: element.value,
        });
      }

      if (ok) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Are you sûr to update reference data?",
            { color: "orange darken-3" }
          )
        ) {
          let v = {
            list: list,
          };
          let r = await this.$maj(FP_UPDATE, v);
          if (r.ok) {
            let d = JSON.parse(r.data.UpdateFingerPrint);
            this.editedItem.details = d[0].elements;
            this.elements_list = d[0].elements;
            this.snackbar_text = "Reference data updated";
            this.snackbar_color = "success";
            this.snackbar = true;
            this.isListClose = true;
            this.element_key++;
            //this.frml++;
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
      }
    },
    sortBy() {
      return function (a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      };
    },

    element_update(item) {
      this.newelement = Object.assign({}, item);
      this.newelement.element_id = this.newelement.element_id.toString();
      this.element_edit = true;
      this.$refs.elmval.focus();
    },
    cancel() {
      if (!this.btn_click) {
        this.btn_click = true;

        this.$refs.form2.resetValidation();
        this.$nextTick(() => {
          this.newelement = {};
        });

        this.element_edit = false;
        this.btn_click = false;
      }
    },
    async save_element() {
      if (!this.btn_click) {
        this.btn_click = true;
        if (this.$refs.form2.validate()) {
          let ok = false;
          this.data_loading = true;
          if (this.newelement.id > -1) {
            let v = {
              fingerprintdetail: {
                id: this.newelement.id,
                element_id: this.newelement.element_id,
                value: this.newelement.value,
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(UPDATE_FP_DETAIL, v);
            this.data_loading = false;
            if (r.ok) {
              this.elements_list.splice(
                this.newelement.index,
                1,
                this.newelement
              );
              this.newelement = {};
              this.$refs.form2.resetValidation();
              ok = true;
            } else {
              this.snackbar_text = "Saving Error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          } else {
            let v = {
              fingerprintdetail: {
                finger_print_id: this.editedItem.id,
                element_id: this.newelement.element_id,
                value: this.newelement.value,
                create_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(CREATE_FP_DETAIL, v);
            if (r.ok) {
              ok = true;
            } else {
              this.snackbar_text = "Saving Error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          }
          if (ok) {
            this.$emit("change", this.editedItem);
            this.$refs.form2.resetValidation();
            this.actualelement = this.newelement;
            this.newelement = {};
            this.element_edit = false;
            let i = this.fluids_elements.findIndex(
              (elm) => elm.id == this.newelement.element_id
            );
            if (i >= 0) {
              this.element.label = this.fluids_elements[i].label;
            }
          } else this.data_loading = false;
        }
        this.btn_click = false;
      }
    },
    ElementSelect(item) {
      this.element = item;
      this.actualelement = item;
    },
    async element_delete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete fluid " + item.element_label + " !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(DELETE_FP_DETAIL, { id: item.id });
        if (r) {
          this.$emit("change", this.editedItem);
        } else this.data_loading = false;
      }
    },
    close() {
      this.$emit("close");
    },

    async validate() {
      if (!this.btn_click) {
        this.btn_click = true;
        if (this.$refs.form.validate()) {
          let c = "";
          let l = this.elements_list.filter((elm) => elm.value == null);
          for (let index = 0; index < l.length; index++) {
            const element = l[index];
            if (index == l.length - 1) c = c + element.element_label;
            else c = c + element.element_label + ",";
          }
          c = l.length > 0 ? "Missing Elements : " + c : "";
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Validate this Reference !!-Are you sur ?",
              { color: "orange darken-3" },
              c
            )
          ) {
            let ok = false;
            let r1 = await this.$requette(FPS_ALL_DETAIL, {
              TypeScope: 1,
              CatScope: [this.editedItem.field_id],
              fluid_id: this.editedItem.fluid_id,
            });
            if (r1.ok) {
              let c = [];
              let l = await r1.data.fingers_prints.filter(
                (elm) => elm.id != this.editedItem.id
              );
              for (let index = 0; index < l.length; index++) {
                const element = l[index];
                let eq = true;
                for (let index = 0; index < element.details.length; index++) {
                  const elm2 = element.details[index];
                  let i = this.elements_list.findIndex(
                    (elm) =>
                      elm.element_id == elm2.element_id &&
                      elm.value != elm2.value
                  );
                  if (i >= 0) {
                    eq = false;
                    break;
                  }
                }
                if (eq)
                  c.push({
                    code: element.code,
                    formation: element.formation_label,
                    well: element.well_name,
                  });
              }
              if (c.length > 0) {
                ok = false;
                let s = "Same elements for these formations  :\n";
                c.forEach((element) => {
                  s =
                    s +
                    element.formation +
                    " (" +
                    element.code +
                    " " +
                    element.well +
                    ")\n";
                });
                if (
                  await this.$refs.confirm.open(
                    "Confirmation",
                    "Same elements!!-Validate this Reference !!-Are you sur ?",
                    { color: "orange darken-3" },
                    s
                  )
                ) {
                  ok = true;
                }
              } else ok = true;
              if (ok) {
                let v = {
                  fingerprint: {
                    id: this.editedItem.id,
                    statut_id: 6,
                    write_uid: this.$store.state.me.id,
                  },
                };
                let r = await this.$maj(UPDATE_FP, v);
                if (r.ok) {
                  this.editedItem.statut_id = 6;
                  this.$emit("change", this.editedItem);
                  this.elements_headers[4].hiden =
                    this.editedItem.statut_id == 6;
                } else {
                  this.snackbar_text = "Saving Error";
                  this.snackbar_color = "error";
                  this.snackbar = true;
                }
              }
            }
          }
        }
        this.btn_click = false;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        //if new refrence verifie if there is fluid with null value

        let i = this.formations_fields_fluids.findIndex(
          (elm) =>
            elm.field_id == this.editedItem.field_id &&
            elm.fluid_id == this.editedItem.fluid_id &&
            elm.formation_id == this.editedItem.formation_id
        );
        if (i >= 0) {
          this.editedItem.formation_field_id =
            this.formations_fields_fluids[i].formation_field_id;
        }

        if (this.editedItem.id > -1) {
          let v = {
            fingerprint: {
              id: this.editedItem.id,
              formation_field_id: this.editedItem.formation_field_id,
              agent_id: this.editedItem.agent_id,
              well_id: this.editedItem.well_id,
              test_type: 1,
              comment: this.editedItem.comment,
              test_date: this.editedItem.test_date,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.$maj(UPDATE_FP, v);
          if (r.ok) {
            this.$emit("change", this.editedItem);
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        } else {
          let v = {
            list: [
              {
                formation_field_id: this.editedItem.formation_field_id,
                agent_id: this.editedItem.agent_id,
                well_id: this.editedItem.well_id,
                test_type: 1,
                statut_id: 5,
                comment: this.editedItem.comment,
                test_date: this.editedItem.test_date,
              },
            ],
            fluid_id: this.editedItem.fluid_id,
          };
          let r = await this.$maj(INSERT_FP, v);
          if (r.ok) {
            let d = JSON.parse(r.data.InsertFingerPrint);
            this.editedItem.id = d[0].id;
            this.editedItem.code = d[0].code;
            this.editedItem.details = d[0].elements;
            this.elements_list = d[0].elements;
            this.element_key++;
            this.$emit("add", this.editedItem);
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
        this.change = 1;
      }
    },
    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
  },
};
</script>
